import { Common, device } from '@stockbit/ui';
import styled from 'styled-components';
import { SectionTitle, SectionSubtitle } from '../styled';

const { Flex } = Common;

const ContentWrapper = styled(Flex)`
  flex-direction: column;

  .big,
  .small {
    width: 100%;
  }

  @media ${device.laptop} {
    align-items: center;
    flex-direction: row;

    .big {
      padding-right: 50px;
      width: calc(100% - 400px);
    }

    .small {
      padding-left: 50px;
      width: 400px;
    }
  }
`;

const Title = styled(SectionTitle)`
  margin: auto;

  @media ${device.laptop} {
    font-size: 36px;
    margin: unset;
    text-align: left;
  }
  @media ${device.laptopL} {
    font-size: 36px;
  }
`;

const Subtitle = styled(SectionSubtitle)`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  @media ${device.laptop} {
    margin-top: 5vh;
    margin-left: unset;
    margin-right: unset;
    text-align: left;
  }
`;

export { ContentWrapper, Title, Subtitle };
